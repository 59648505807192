import { useTranslations } from 'next-intl'
import { BreadcrumbJsonLd } from 'next-seo'
import { useRouter } from 'next/router'
import { brandConfig } from '@/config/brand'
import { PageMetaTags } from '@/features/page/page-meta-tags'
import { PagePanelsFetcher } from '@/features/page/page-panels-fetcher'
import { paths } from '@/helpers/paths'
import { NordicAppWithColorFade } from '@/layouts/app'
import { ErrorHandler } from '@/layouts/error-handler'
import type { Page } from '@/pages/types'
import { stringFromQueryParam } from '@/utils/query-string'

const CategoryPage: Page = () => {
  const router = useRouter()
  const id = stringFromQueryParam(router.query.id)
  const t = useTranslations()

  if (!id) return <ErrorHandler statusCode={404} />

  return (
    <>
      <PagePanelsFetcher
        id={id}
        showBreadcrumbs
        renderMetaTags={(pageQuery) => {
          const title = pageQuery.page?.title

          if (!title) return null

          const breadcrumbItems = [
            {
              position: 1,
              name: t('breadcrumb__categories'),
              item: brandConfig.domain + paths.categories.urlString(),
            },
            { position: 2, name: title, item: brandConfig.domain + paths.page.urlString({ id }) },
          ]

          return <BreadcrumbJsonLd itemListElements={breadcrumbItems} />
        }}
      />
      <PageMetaTags pageId={id} />
    </>
  )
}

CategoryPage.getLayout = (Page, props) => {
  return (
    <NordicAppWithColorFade shouldOverrideHeaderMarginBehaviour>
      <Page {...props} />
    </NordicAppWithColorFade>
  )
}

export default CategoryPage
